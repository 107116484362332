var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "pos": "relative",
      "w": "100%",
      "h": "100%",
      "mx": "auto",
      "bg": ['#F2F2F2', '#FFF'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "p": ['1rem', '1.5rem'],
      "min-height": ['calc(100vh - 62px)', '74vh']
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": [{
        label: 'Dashboard',
        href: '/'
      }, {
        label: 'Keranjang',
        href: '/cart'
      }, {
        label: 'Checkout',
        href: '/checkout',
        isCurrent: true
      }]
    }
  })], 1), _c('c-box', {
    attrs: {
      "w": ['100%', '600px'],
      "mx": "auto",
      "padding-bottom": ['50px', '0px']
    }
  }, [_c('c-text', {
    attrs: {
      "d": ['none', 'block'],
      "font-family": "Roboto",
      "font-size": "20px",
      "color": "#333",
      "font-weight": "700",
      "mb": "1rem",
      "align": "center"
    }
  }, [_vm._v(" Pembayaran ")]), _c('c-flex', {
    attrs: {
      "flex-direction": "row",
      "justify-content": "space-between",
      "align-items": "center",
      "background": "#FFFFFF",
      "box-shadow": "2px 2px 10px rgba(0, 0, 0, 0.15)",
      "border-radius": "12px",
      "padding": ['16px', '24px'],
      "mb": "24px"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": ['column', 'row'],
      "flex-grow": "1",
      "align-items": ['start', 'center'],
      "width": "100%"
    }
  }, [_c('c-image', {
    attrs: {
      "d": ['none', 'block'],
      "src": require('@/assets/icons/icon-payment.svg'),
      "w": "40px",
      "h": "40px",
      "mr": "1rem"
    }
  }), _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['16px', '20px'],
      "color": "#333",
      "font-weight": ['500', '700']
    }
  }, [_vm._v(" Total Pembayaran ")]), _c('c-text', {
    attrs: {
      "d": ['block', 'none'],
      "font-family": "Roboto",
      "font-size": "12px",
      "color": "#333",
      "font-weight": ['400', '700'],
      "mt": "4px"
    }
  }, [_vm._v(" No. Pesanan #" + _vm._s(_vm.transaction.invoiceNumber) + " ")])], 1), _c('c-text', {
    attrs: {
      "flex-shrink": "0",
      "font-family": "Roboto",
      "font-size": ['20px', '28px'],
      "color": "#00A68C",
      "font-weight": "700"
    }
  }, [_vm._v(" " + _vm._s(_vm.getTotal) + " ")])], 1), _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['16px', '20px'],
      "color": "#333",
      "font-weight": ['500', '700'],
      "mb": ['1rem', '1rem'],
      "text-align": ['left', 'center']
    }
  }, [_vm._v(" Pilih Metode Pembayaran ")]), _c('PaymentMethod', {
    attrs: {
      "detail-payment": _vm.detailPayment,
      "loading": _vm.loading,
      "padding-bottom": true
    },
    on: {
      "handleChangePaymentMethod": _vm.handleChangePaymentMethod,
      "handleChangePaymentMethodId": _vm.handleChangePaymentMethodId
    },
    model: {
      value: _vm.selectedPayment,
      callback: function callback($$v) {
        _vm.selectedPayment = $$v;
      },
      expression: "selectedPayment"
    }
  }), _c('c-flex', {
    attrs: {
      "justify-content": "center"
    }
  }, [_c('c-button', {
    attrs: {
      "d": ['none', 'block'],
      "background": "#008C81",
      "color": "#FFF",
      "w": "400px",
      "font-family": "Roboto",
      "font-size": "16px",
      "font-weight": "500",
      "border-radius": "50px",
      "p": "16px 50px",
      "h": "50px",
      "mt": "1rem",
      "disabled": _vm.isDisabledPayment,
      "is-loading": _vm.loading,
      "loading-text": "Memperbarui Pembayaran"
    },
    on: {
      "click": function click($event) {
        _vm.transaction.paymentMethodId != null ? _vm.onOpenConfirm() : _vm.submitPayment();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.transaction.paymentMethodId ? 'Ubah Metode Pembayaran' : 'Lanjutkan Pembayaran') + " ")])], 1)], 1), _c('c-flex', {
    attrs: {
      "display": ['block', 'none'],
      "pos": "fixed",
      "bottom": "0",
      "left": "0",
      "right": "0",
      "justify-content": "center",
      "w": "100%",
      "background-color": "#F2F2F2",
      "padding-block": "12px",
      "padding-inline": "16px"
    }
  }, [_c('c-button', {
    attrs: {
      "display": "block",
      "background": "#008C81",
      "color": "#FFF",
      "w": "100%",
      "font-family": "Roboto",
      "font-size": "14px",
      "font-weight": "500",
      "border-radius": "50px",
      "p": "10px 50px",
      "h": "50px",
      "disabled": _vm.isDisabledPayment,
      "is-loading": _vm.loading,
      "loading-text": "Memperbarui Pembayaran"
    },
    on: {
      "click": function click($event) {
        _vm.transaction.paymentMethodId != null ? _vm.onOpenConfirm() : _vm.submitPayment();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.transaction.paymentMethodId ? 'Ubah Metode Pembayaran' : 'Lanjutkan Pembayaran') + " ")])], 1), _c('ModalConfirm', {
    attrs: {
      "title": "Apa kamu yakin ingin mengganti metode pembayaran?",
      "is-open": _vm.isConfirm
    },
    on: {
      "close": _vm.onCloseConfirm,
      "submit": function submit() {
        _vm.onCloseConfirm();

        _vm.submitPayment();
      }
    }
  }), _c('ModalInfo', {
    attrs: {
      "for": "confirmExitTransactionPage",
      "is-open": _vm.paymentPage.isOpenModalConfirmExitTransactionPage,
      "with-button-close": false,
      "close-on-overlay-click": false,
      "is-loading-exit-page": _vm.isLoadingExitPage
    },
    on: {
      "close": function close($event) {
        return _vm.setPaymentPage({
          isOpenModalConfirmExitTransactionPage: false,
          customChangeFn: ''
        });
      },
      "handle-exit-page": _vm.handleExitPage
    }
  }), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenPopupCouponError,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "160px",
            "object-fit": "cover",
            "src": require('@/assets/images/illustration-090323-raw.jpeg'),
            "alt": "image cant use voucher"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "flex-direction": "column",
            "justify-content": "center",
            "align-items": "center",
            "padding": "30px 24px",
            "gap": "16px"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "18px",
            "size-desktop": "20px",
            "color": "primary.400",
            "text-align": "center"
          }
        }, [_vm._v(" Yah! Voucher kamu tidak "), _c('br'), _vm._v(" dapat digunakan ")]), _c('BaseText', {
          attrs: {
            "size-mobile": "14px-2",
            "size-desktop": "16px",
            "text-align": "center"
          }
        }, [_vm._v(" Kamu terlalu lama di halaman ini, "), _c('br'), _vm._v(" silahkan masukkan ulang kode voucher ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px",
            "display": "flex",
            "justify-content": "center",
            "padding": "0 30%"
          }
        }, [_c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": _vm.handleClosePopupCouponError
          }
        }, [_vm._v(" Kembali ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }